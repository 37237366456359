@import '../variables';
@import '../mixins';

.dropdown {
  position: relative;
  display: inline;

  button.btn-dropdown {
    display: flex;
    align-items: center;

    &.btn-dropdown--transparent {
      background: none;
      border: none; }

    &.btn-primary {
      background: $color-primary;
      color: white;
      padding: 4px 5px 5px 10px;
      border-radius: 5px;

      i {
        color: white; } }

    i {
      margin-left: 10px; } }

  .dropdown-options {
    background: white;
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.10), 0 0 40px rgba(0, 0, 0, 0.15);
    min-width: 150px;
    overflow: hidden;

    &.dropdown-position-left {
      left: 0;

      &:before {
        left: 5px; } }

    &.dropdown-position-right {
      left: initial;
      right: 0;

      &:before {
        right: 5px; } }

    &:before {
      position: absolute;
      top: -5px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white; }

    .dropdown--item,
    .dropdown--item * {
      font: {
        family: $font-secondary;
        size: $base-font-size;
        weight: 500; } }

    .dropdown--item {
      display: flex;
      align-items: center;
      height: 35px;
      padding: 0 10px;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background: lighten($light-gray, 45%); }

      &.dropdown--item-primary {
        background: $color-primary;
        color: white; } } } }

