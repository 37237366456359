@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
.full-size {
  display: flex;
  align-items: center;
  justify-content: center; }

.full-size {
  display: flex;
  align-items: center;
  justify-content: center; }

.notification-anim-enter {
  opacity: 0.01;
  transform: translateX(100px); }

.notification-anim-enter.notification-anim-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.notification-anim-exit {
  opacity: 1;
  transform: translateX(0); }

.notification-anim-exit.notification-anim-exit-active {
  opacity: 0.01;
  transform: translateX(100px);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.notification-anim-to-right-enter {
  opacity: 0.01;
  transform: translateX(-100px); }

.notification-anim-to-right-enter.notification-anim-to-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.notification-anim-to-right-exit {
  opacity: 1;
  transform: translateX(0); }

.notification-anim-to-right-exit.notification-anim-to-right-exit-active {
  opacity: 0.01;
  transform: translateX(-100px);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.fade-scale-enter {
  opacity: 0;
  transform: scale(1.8); }

.fade-scale-enter-done {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s; }

.fade-scale-exit {
  opacity: 1;
  transform: scale(1); }

.fade-scale-exit-active {
  opacity: 0;
  transform: scale(1.8);
  transition: opacity 0.3s, transform 0.3s; }

.notification-item-container-enter {
  opacity: 0; }

.notification-item-container-enter-active {
  opacity: 1;
  transition: all 150ms ease-in; }

.notification-item-container-exit {
  opacity: 1; }

.notification-item-container-exit-active {
  opacity: 0;
  transition: all 150ms ease-in; }

.page-transition-appear .page-header {
  opacity: 0.01;
  transform: translateY(-40px); }
  .page-transition-appear .page-header .page-actions a {
    transform: translateY(-20px);
    opacity: 0.01; }

.page-transition-appear .page-content {
  opacity: 0.01;
  transform: translateY(80px); }
  .page-transition-appear .page-content .filter-container, .page-transition-appear .page-content .table-animate, .page-transition-appear .page-content .tab-body, .page-transition-appear .page-content .dashboard-container .card {
    opacity: 0.01;
    transform: translateY(100px); }

.page-transition-appear-done .page-content, .page-transition-appear-done .page-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.page-transition-appear-done .page-content .filter-container, .page-transition-appear-done .page-content .table-animate, .page-transition-appear-done .page-content .tab-body, .page-transition-appear-done .page-content .dashboard-container .card {
  opacity: 1;
  transform: translateY(0);
  transition: 0.3s transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s box-shadow 0.2s linear; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(1) {
  transition-delay: 0.1s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(2) {
  transition-delay: 0.2s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(3) {
  transition-delay: 0.3s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(4) {
  transition-delay: 0.4s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(5) {
  transition-delay: 0.5s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(6) {
  transition-delay: 0.6s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(7) {
  transition-delay: 0.7s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(8) {
  transition-delay: 0.8s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(9) {
  transition-delay: 0.9s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(10) {
  transition-delay: 1s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(11) {
  transition-delay: 1.1s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(12) {
  transition-delay: 1.2s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(13) {
  transition-delay: 1.3s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(14) {
  transition-delay: 1.4s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(15) {
  transition-delay: 1.5s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(16) {
  transition-delay: 1.6s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(17) {
  transition-delay: 1.7s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(18) {
  transition-delay: 1.8s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(19) {
  transition-delay: 1.9s; }

.page-transition-appear-done .page-header {
  transition-duration: 0.5s; }
  .page-transition-appear-done .page-header .page-actions a {
    opacity: 1;
    transform: translateY(0);
    transition: 0.3s all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .page-transition-appear-done .page-header .page-actions a:nth-child(1) {
    transition-delay: 0.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(2) {
    transition-delay: 0.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(3) {
    transition-delay: 0.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(4) {
    transition-delay: 1s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(5) {
    transition-delay: 1.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(6) {
    transition-delay: 1.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(7) {
    transition-delay: 1.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(8) {
    transition-delay: 2s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(9) {
    transition-delay: 2.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(10) {
    transition-delay: 2.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(11) {
    transition-delay: 2.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(12) {
    transition-delay: 3s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(13) {
    transition-delay: 3.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(14) {
    transition-delay: 3.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(15) {
    transition-delay: 3.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(16) {
    transition-delay: 4s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(17) {
    transition-delay: 4.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(18) {
    transition-delay: 4.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(19) {
    transition-delay: 4.75s; }

.full-size {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999; }

.lds-ripple {
  display: inline-block;
  position: relative; }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #f59637;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .lds-ripple div:nth-child(2) {
      animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/fonts/MaterialIcons-Regular.woff2) format("woff2"), url(/fonts/MaterialIcons-Regular.woff) format("woff"), url(/fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.blinking {
  animation: blinkingText 0.8s infinite; }

@keyframes animate-item {
  0% {
    transform: translateX(-300px); }
  100% {
    transform: translateX(1400px); } }

@keyframes blinkingText {
  0% {
    color: #000; }
  50% {
    color: transparent; }
  100% {
    color: #000; } }

.item-preloader {
  position: relative;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden; }
  .item-preloader:before {
    content: '';
    position: absolute;
    width: 250px;
    height: 100%;
    animation: animate-item 2s linear infinite; }
  .item-preloader.item-preloader--light {
    background: rgba(255, 255, 255, 0.2); }
    .item-preloader.item-preloader--light:before {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.01)); }
  .item-preloader.item-preloader--dark {
    background: rgba(0, 0, 0, 0.1); }
    .item-preloader.item-preloader--dark:before {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.01)); }

::-webkit-scrollbar {
  width: 9px;
  height: 9px; }

::-webkit-scrollbar-track {
  background: black; }

::-webkit-scrollbar-thumb {
  background: #f59637; }

::-webkit-scrollbar-thumb:hover {
  background: #b30000; }

[draggable] {
  cursor: pointer; }

.no-margin {
  margin: 0 !important; }

.ff-2 {
  font-family: "Lato", sans-serif; }

.fs-xs {
  font-size: 12px; }

.fs-sm {
  font-size: 14px; }

.fs-md {
  font-size: 14px; }

.fs-lg {
  font-size: 16px; }

.text-center {
  text-align: center !important; }

.top-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2099; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a, button {
  cursor: pointer;
  border: none;
  text-decoration: none; }

body {
  font-family: "Lato", sans-serif;
  font-size: 14px; }

textarea, input, select {
  font-family: "Lato", sans-serif;
  font-size: 12px; }

.page-holder {
  position: relative;
  z-index: 2;
  margin-top: 200px; }
  .page-holder--container {
    min-height: calc(100vh - 200px);
    background: #efefef; }

.container {
  display: flex;
  max-width: 1390px;
  width: 100%;
  flex-direction: column;
  margin: 0 auto; }
  .container.container-horizontal {
    flex-direction: row; }
  .container.content-container {
    padding-top: 40px;
    padding-bottom: 40px; }
    @media only screen and (max-width: 576px) {
      .container.content-container {
        padding-top: 20px; } }
  .container.container--no-pading {
    padding: 0; }

.container--full-width, .full-width {
  width: 100%; }

.width--initial {
  width: initial !important; }

.title {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.page-title {
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px; }
  .page-title span {
    margin-left: 20px;
    color: #bfbfbf; }

.page-content {
  margin-top: 20px; }
  @media only screen and (max-width: 576px) {
    .page-content {
      flex-direction: column; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  min-width: 150px;
  padding-left: 10px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  overflow: hidden; }
  .btn[disabled] {
    opacity: 0.4; }
  .btn.btn-transparent {
    height: initial;
    min-width: initial;
    padding: 0;
    background: none; }
  .btn.btn-icon-only {
    min-width: auto;
    text-align: center;
    padding: 0 10px; }
    .btn.btn-icon-only:not(:first-of-type) {
      margin-left: 5px; }
    .btn.btn-icon-only i {
      font-size: 20px; }
  .btn i, .btn img {
    transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .btn img.loader {
    height: 70%;
    position: absolute;
    right: -100px;
    opacity: 0; }
  .btn.btn-primary {
    background: #54534A;
    color: white !important;
    transition: all 0.15s linear; }
    .btn.btn-primary:hover:not(:disabled) {
      background: #f59637; }
  .btn.btn-danger {
    background: #ff5454;
    color: white; }
  .btn.no-animation {
    justify-content: space-between;
    padding-right: 10px; }
  .btn:not(.no-animation).btn-with-icon {
    justify-content: space-between;
    transition: 0.5s padding-right cubic-bezier(0.165, 0.84, 0.44, 1), 0.8s padding-left cubic-bezier(0.165, 0.84, 0.44, 1); }
    .btn:not(.no-animation).btn-with-icon i {
      font-size: 20px;
      transform: translateX(0);
      opacity: 1;
      line-height: 1; }
    .btn:not(.no-animation).btn-with-icon:hover {
      padding-left: 25px;
      padding-right: 10px; }
  .btn.btn-loading img {
    right: 10px;
    opacity: 1; }
  .btn.btn-loading.btn-with-icon i {
    transform: translateX(100px); }
  .btn.btn--no-radius {
    border-radius: 0; }
  .btn.btn-text-transform--none {
    text-transform: none; }
  .btn.btn-visibility {
    background: white;
    min-width: initial;
    text-transform: none;
    margin-left: 5px;
    padding: 0 5px 0 0;
    transition: 0.15s all linear; }
    .btn.btn-visibility:hover, .btn.btn-visibility:active, .btn.btn-visibility:focus {
      background: #ffd8b0;
      padding: 5px 10px 5px 5px; }
      .btn.btn-visibility:hover i.cc-close, .btn.btn-visibility:active i.cc-close, .btn.btn-visibility:focus i.cc-close {
        color: black !important; }
    .btn.btn-visibility button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;
      padding: 5px 0 5px 5px; }
    .btn.btn-visibility.btn-visibility--active {
      background: #f59637; }
      .btn.btn-visibility.btn-visibility--active button span, .btn.btn-visibility.btn-visibility--active button i {
        color: white !important; }
      .btn.btn-visibility.btn-visibility--active i.cc-close {
        color: rgba(255, 255, 255, 0.4) !important; }
        .btn.btn-visibility.btn-visibility--active i.cc-close:hover {
          color: rgba(255, 255, 255, 0.9) !important; }
    .btn.btn-visibility span {
      margin-right: 10px; }
    .btn.btn-visibility i {
      font-size: 20px;
      margin-right: 5px; }
      .btn.btn-visibility i.cc-close {
        margin-right: 0;
        cursor: pointer;
        transition: 0.1s transform linear; }
        .btn.btn-visibility i.cc-close:hover {
          transform: rotate(90deg);
          color: #f59637 !important; }

.input-group input,
.input-group select,
.input-group textarea,
.input-group-combined input {
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px; }

.input-group {
  position: relative; }
  .input-group i.right {
    position: absolute;
    top: 5px;
    right: 5px; }
  .input-group.input-group--big-margin {
    margin-bottom: 10px; }
  .input-group label {
    font-size: 12px;
    margin-bottom: 5px;
    display: block; }
  .input-group input, .input-group select, .input-group textarea {
    width: 100%;
    height: 33px;
    margin-bottom: 8px;
    background: white;
    border: 1px solid #808080; }
  .input-group textarea {
    height: 100px;
    padding: 10px; }

.input-group-combined {
  display: flex; }
  .input-group-combined input {
    flex: 1;
    border-right: none;
    border-radius: 5px 0 0 5px;
    margin: 0; }
  .input-group-combined button {
    border-radius: 0 5px 5px 0 !important; }

.form-title {
  margin-bottom: 10px; }

.flex {
  display: flex; }
  .flex--1 {
    flex: 1; }
  .flex.flex-jc--space-between {
    justify-content: space-between; }
  .flex-jc--space-around {
    justify-content: space-around; }
  .flex.flex-jc--center {
    justify-content: center; }
  .flex.flex-jc--flex-end {
    justify-content: flex-end; }
  .flex.flex-ai--flex-start {
    align-items: flex-start; }
  .flex.flex-ai--flex-end {
    align-items: flex-end; }
  .flex.flex-ai--center {
    align-items: center; }
  .flex.flex-fd--column {
    flex-direction: column; }
  @media only screen and (max-width: 576px) {
    .flex.m-flex-fd--column {
      flex-direction: column; } }
  .flex.flex-fd--row {
    flex-direction: row; }
  .flex.flex-fw--wrap {
    flex-wrap: wrap; }

.text-bold {
  font-weight: 500 !important; }

.font-bold {
  font-weight: bold !important; }

.width--1-7 {
  width: calc(calc(100% / 7) - 10px) !important; }

.width--1-6 {
  width: calc(calc(100% / 6) - 10px) !important; }

.width--1-5 {
  width: calc(calc(100% / 5) - 10px) !important; }

.width--1-4 {
  width: calc(calc(100% / 4) - 10px) !important; }

.width--1-3 {
  width: calc(calc(100% / 3) - 10px) !important; }

.width--1-2 {
  width: calc(50% - 10px) !important; }

.width--3-4 {
  width: calc(75% - 10px) !important; }

@media only screen and (max-width: 576px) {
  .width--1-4, .width--1-3, .width--1-2, .width--3-4 {
    width: 100% !important; } }

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .width--1-4, .width--1-3, .width--1-2, .width--3-4 {
    width: 100% !important; } }

@media only screen and (max-width: 576px) {
  .page-holder--container {
    padding: 0 15px; } }

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .page-holder--container {
    padding: 0 15px; } }

.no-background {
  background: none; }

.btn--no-background {
  background: none; }

.btn--border {
  border: 1px solid #e4e4e4; }

#collicare-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: none;
  background: red; }
  #collicare-backdrop.backdrop--is-open {
    display: block;
    z-index: 1; }

.align-center {
  text-align: center !important; }

button {
  font-size: 14px; }
  button.button--margin-bottom {
    margin-bottom: 15px; }

input.input, select.input {
  width: 100%;
  height: 33px;
  display: block;
  border: 1px solid #808080;
    border-radius: 4px;
  background: none;
  margin-bottom: 8px;
  font-size: 12px;
  padding: 0 6px; }
  input.input.input--no-margin-bottom, select.input.input--no-margin-bottom {
    margin-bottom: 0; }

.float-right {
  float: right; }

.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0 !important; }

.page-actions a {
  padding-right: 10px; }
  .page-actions a:not(:first-of-type) {
    margin-left: 5px; }

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .contact-container div.img-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: cover !important;
    background-position: center !important; }
  .contact-container div.details {
    margin-left: 30px; }
    .contact-container div.details [class^="details-"] {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .contact-container div.details [class^="details-"] i {
        margin-right: 5px;
        font-size: 22px !important; }
    .contact-container div.details [class$="name"] {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px; }

.margin-top--20 {
  margin-top: 20px; }

.margin-top--10 {
  margin-top: 10px; }

.margin-top--5 {
  margin-top: 5px; }

.margin-right--10 {
  margin-right: 10px; }

.margin-right--20 {
  margin-right: 20px; }

.padding-7 {
  padding: 7px; }

.default-avatar {
  color: #9a9a9a !important;
  font-size: 40px !important; }
  .default-avatar--big {
    font-size: 200px !important; }
  .default-avatar--contacts {
    font-size: 140px !important; }

.image-profile {
  background-size: cover;
  background-position: center; }

.form--border-bottom {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px; }

.form--padding-bottom {
  padding-bottom: 15px; }

@media only screen and (max-width: 576px) {
  .customer-support-container {
    flex-direction: column; }
    .customer-support-container .accordion-content {
      max-height: 3000px; } }

@media only screen and (max-width: 576px) {
  .page-header {
    flex-direction: column;
    margin-bottom: 10px; } }

.list-item-form {
  background: #eee;
  padding: 15px;
  margin-top: 5px;
  border-radius: 5px; }
  .list-item-form button {
    margin-top: 5px; }
  .list-item-form input, .list-item-form textarea {
    background: white;
    border: 1px solid #e4e4e4; }

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; }

.wave-graph-content {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.wave-diagram-filter-dropdown-item button {
  background: none !important;
  border-width: 0 1px 0 0 !important;
  padding: 10px 10px; }
  .wave-diagram-filter-dropdown-item button span {
    font-size: 14px !important; }

.wave-chart-container {
  padding: 10px 0 0; }
  .wave-chart-container .wave-chart-header {
    padding-right: 5px; }
    .wave-chart-container .wave-chart-header .wave-chart-header-dot {
      height: 10px;
      width: 10px;
      background: #f59637 !important;
      border-radius: 10px;
      margin-right: 5px; }
      .wave-chart-container .wave-chart-header .wave-chart-header-dot-prev {
        background: #8d8c86 !important; }
  .wave-chart-container .wave-chart-body {
    margin-left: -15px; }

.calendar-header {
  font-size: 20px; }

.calendar-modal {
  position: absolute;
  right: 20px;
  z-index: 9999;
  width: 1040px;
  background: #f7f7f7;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 25px; }
  .calendar-modal--left {
    right: auto;
    left: 20px; }
  .calendar-modal input.input {
    background: white;
    border: 1px solid #f2f2f2;
    margin-bottom: 0; }

.filter-button button {
  border-radius: 5px;
  padding: 10px 45px;
  font-weight: bold;
  width: calc(50% - 12px); }

.filter-button .filter-button-item {
  background-color: #f59637;
  color: #FFFFFF; }

.filter-button .filter-button-cancel {
  background: white;
  color: black; }

.wave-diagram-select p {
  margin-right: 20px; }

.wave-diagram-select .select button {
  background: white; }

.wave-diagram-select .select .icon {
  border-left: 1px solid #ededed; }

.wave-diagram-switch {
  margin: 10px 0; }
  .wave-diagram-switch p {
    margin-left: 10px; }

.compared-to {
  font-size: 13px;
  color: #bfbfbf; }

.pie-chart {
  padding: 0 15px; }

.d-pie-chart + .d-pie-chart {
  z-index: 9; }

.draggable-content.draggable-content--no-padding.pie-chart-1 {
  padding-bottom: 20px !important; }

.custom-container {
  padding: 30px;
  position: relative; }
  .custom-container .page-header {
    width: calc(100vw - 64px) !important;
    position: sticky;
    left: 30px; }
  .custom-container .page-content .filter-action-buttons {
    position: sticky;
    width: calc(100vw - 64px) !important;
    left: 30px; }
  .custom-container .page-content .table-filter {
    margin-top: 2px; }
  .custom-container .page-content .custom-paginator {
    display: flex;
    justify-content: flex-end; }
  .custom-container .page-content .pagination {
    position: sticky;
    right: 30px; }

.overflow-x-scroll {
  overflow-x: scroll; }

.rounded-icons {
  position: relative;
  -webkit-transition: 0.3s border cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: 0.3s border cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .rounded-icons i {
    color: #404040 !important;
    background: #F1F1F1;
    padding: 4px;
    border-radius: 50%; }
  .rounded-icons i:hover {
    color: #ff5454 !important;
    background: #F5F5F5; }
