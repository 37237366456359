@import './variables';
@import './mixins';

.notification-anim-enter {
  opacity: 0.01;
  transform: translateX(100px); }

.notification-anim-enter.notification-anim-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 150ms $ease-in-out-quad; }

.notification-anim-exit {
  opacity: 1;
  transform: translateX(0); }

.notification-anim-exit.notification-anim-exit-active {
  opacity: 0.01;
  transform: translateX(100px);
  transition: all 150ms $ease-in-out-quad; }

.notification-anim-to-right-enter {
  opacity: 0.01;
  transform: translateX(-100px); }

.notification-anim-to-right-enter.notification-anim-to-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 150ms $ease-in-out-quad; }

.notification-anim-to-right-exit {
  opacity: 1;
  transform: translateX(0); }

.notification-anim-to-right-exit.notification-anim-to-right-exit-active {
  opacity: 0.01;
  transform: translateX(-100px);
  transition: all 150ms $ease-in-out-quad; }

.fade-scale-enter {
  opacity: 0;
  transform: scale(1.8); }

.fade-scale-enter-done {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s; }

.fade-scale-exit {
  opacity: 1;
  transform: scale(1); }

.fade-scale-exit-active {
  opacity: 0;
  transform: scale(1.8);
  transition: opacity 0.3s, transform 0.3s; }

.notification-item-container-enter {
  opacity: 0; }

.notification-item-container-enter-active {
  opacity: 1;
  transition: all 150ms ease-in; }

.notification-item-container-exit {
  opacity: 1; }

.notification-item-container-exit-active {
  opacity: 0;
  transition: all 150ms ease-in; }

.page-transition-appear {
  .page-header {
    opacity: 0.01;
    transform: translateY(-40px);

    .page-actions a {
      transform: translateY(-20px);
      opacity: 0.01; } }

  .page-content {
    opacity: 0.01;
    transform: translateY(80px);

    .filter-container, .table-animate, .tab-body, .dashboard-container .card {
      opacity: 0.01;
      transform: translateY(100px); } }

  @at-root #{&}-done {
    .page-content, .page-header {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s $ease-in-out-cubic; }

    .page-content {
      .filter-container, .table-animate, .tab-body, .dashboard-container .card {
        opacity: 1;
        transform: translateY(0);
        transition: 0.3s transform 0.2s $ease-in-out-cubic, 0.3s opacity 0.2s $ease-in-out-cubic, 0.3s box-shadow 0.2s linear; }

      @for $i from 1 to 20 {
        .dashboard-container .card:nth-child(#{$i}) {
          transition-delay: $i * 0.1s; } }

      .table-animate {
 } }        // transition: 0.3s width 0.15s $ease-in-out-cubic

    .page-header {
      transition-duration: 0.5s;

      .page-actions a {
        opacity: 1;
        transform: translateY(0);
        transition: 0.3s all 0.4s $ease-in-out-cubic; }

      @for $i from 1 to 20 {
        .page-actions a:nth-child(#{$i}) {
          transition-delay: $i * 0.25s; } } } } }

.full-size {
  @include size(100vw, 100vh);
  @include position(fixed, (0, 0, 0, 0));
  @extend %centered-flex;
  z-index: 999; }

.lds-ripple {
  display: inline-block;
  position: relative;

  div {
    position: absolute;
    border: 4px solid $orange;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s; } } }

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }
