@import 'variables';
@import 'mixins';
@import 'animate';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot);
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(/fonts/MaterialIcons-Regular.woff2) format('woff2'), url(/fonts/MaterialIcons-Regular.woff) format('woff'), url(/fonts/MaterialIcons-Regular.ttf) format('truetype'); }

.blinking {
	animation: blinkingText 0.8s infinite; }

@keyframes animate-item {
  0% {
    transform: translateX(-300px); }

  100% {
    transform: translateX(1400px); } }

@keyframes blinkingText {
	0% {
    color: #000; }
	50% {
    color: transparent; }
	100% {
    color: #000; } }

.item-preloader {
  position: relative;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 250px;
    height: 100%;
    animation: animate-item 2s linear infinite; }

  &.item-preloader--light {
    background: rgba(255, 255, 255, 0.2);

    &:before {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.01)); } }

  &.item-preloader--dark {
    background: rgba(0, 0, 0, 0.1);

    &:before {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.01)); } } }

::-webkit-scrollbar {
  width: 9px;
  height: 9px; }

::-webkit-scrollbar-track {
  background: black; }

::-webkit-scrollbar-thumb {
  background: $color-primary; }

::-webkit-scrollbar-thumb:hover {
  background: #b30000; }

[draggable] {
  cursor: pointer; }

.no-margin {
  margin: 0 !important; }

.ff-2 {
  font-family: $font-secondary; }

.fs-xs {
  font-size: 12px; }

.fs-sm {
  font-size: $base-font-size; }

.fs-md {
  font-size: 14px; }

.fs-lg {
  font-size: 16px; }

.text-center {
  text-align: center !important; }

.top-container {
  // position: sticky
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2099; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a, button {
  cursor: pointer;
  border: none;
  text-decoration: none; }

body {
  font: {
    family: $font-primary;
    size: $base-font-size; }
 }  // overflow-x: hidden

textarea, input, select {
  font: {
    family: $font-primary;
    size: 12px; } }

.page-holder {
  position: relative;
  z-index: 2;
  margin-top: 200px;

  &--container {
    min-height: calc(100vh - 200px);
    background: $color-bg-default; } }

.container {
  display: flex;
  max-width: $base-container-width;
  width: 100%;
  @include flex-direction(column);
  margin: 0 auto;

  &.container-horizontal {
    @include flex-direction(row); }

  &.content-container {
    padding: {
      top: 40px;
      bottom: 40px; }

    @include xs {
      padding-top: 20px; } }

  &.container--no-pading {
    padding: 0; } }

.container--full-width, .full-width {
  width: 100%; }

.width--initial {
  width: initial !important; }

.title {
  font: {
    family: $font-secondary;
    size: $title-font-size;
    weight: $title-font-weight; } }

.page-title {
  font: {
    family: $font-secondary;
    size: $page-title-font-size;
    weight: $page-title-font-weight; }
  margin-bottom: 20px;

  span {
    margin-left: 20px;
    color: lighten(black, 75%); } }


.page-content {
  margin-top: 20px;

  @include xs {
    flex-direction: column; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.btn {
  position: relative;
  @include flex(center, center);
  height: 33px;
  min-width: 150px;
  padding: {
    left: 10px;
    right: 20px; }
  border-radius: 5px;
  font: {
    family: $font-secondary;
    size: $base-font-size; }
  // text-transform: uppercase
  overflow: hidden;

  &[disabled] {
    opacity: 0.4; }

  &.btn-transparent {
    height: initial;
    min-width: initial;
    padding: 0;
    background: none; }

  &.btn-icon-only {
    min-width: auto;
    text-align: center;
    padding: 0 10px;

    &:not(:first-of-type) {
      margin-left: 5px; }

    i {
      font-size: 20px; } }

  i, img {
    transition: 0.5s all $ease-out-quart; }

  img.loader {
    height: 70%;
    position: absolute;
    right: -100px;
    opacity: 0; }

  &.btn-primary {
    background: $primary-button-color;
    color: white !important;
    transition: all 0.15s linear;

    &:hover:not(:disabled) {
      background: $orange; } }

  &.btn-danger {
    background: $color-danger;
    color: white; }

  &.no-animation {
    justify-content: space-between;
    padding-right: 10px; }

  &:not(.no-animation).btn-with-icon {
    justify-content: space-between;
    transition: 0.5s padding-right $ease-out-quart, 0.8s padding-left $ease-out-quart;

    i {
      font-size: 20px;
      transform: translateX(0);
      opacity: 1;
      line-height: 1; }

    &:hover {
      padding: {
        left: 25px;
        right: 10px; } } }

  &.btn-loading {
    img {
      right: 10px;
      opacity: 1; }

    &.btn-with-icon {
      i {
        transform: translateX(100px); } } }

  &.btn--no-radius {
    border-radius: 0; }

  &.btn-text-transform--none {
    text-transform: none; }

  &.btn-visibility {
    background: white;
    min-width: initial;
    text-transform: none;
    margin-left: 5px;
    padding: 0 5px 0 0;
    transition: 0.15s all linear;

    &:hover, &:active, &:focus {
      background: #ffd8b0;
      padding: 5px 10px 5px 5px;

      i.cc-close {
        color: black !important; } }

    button {
      @include flex(center, space-between);
      background: none;
      padding: 5px 0 5px 5px; }

    &.btn-visibility--active {
      background: $color-primary;

      button {
        span, i {
          color: white !important; } }

      i.cc-close {
        color: rgba(255, 255, 255, 0.4) !important;

        &:hover {
          color: rgba(255, 255, 255, 0.9) !important; } } }

    span {
      margin-right: 10px; }

    i {
      font-size: 20px;
      margin-right: 5px;

      &.cc-close {
        margin-right: 0;
        cursor: pointer;
        transition: 0.1s transform linear;

        &:hover {
          transform: rotate(90deg);
          color: $color-primary !important; } } } } }

.input-group input,
.input-group select,
.input-group textarea,
.input-group-combined input {
  border: {
    color: $color-input-border;
    width: 1px;
    style: solid;
    radius: 5px; }
  padding: {
    left: $gutter;
    right: $gutter; } }

.input-group {
  position: relative;

  i.right {
    position: absolute;
    top: 5px;
    right: 5px; }

  &.input-group--big-margin {
    margin-bottom: 10px; }

  label {
    font-size: 12px;
    margin-bottom: 5px;
    display: block; }

  input, select, textarea {
    @include size(100%, 33px);
    margin-bottom: 8px;
    background: white;
    border: 1px solid #808080; }

  textarea {
    height: 100px;
    padding: $gutter; } }

.input-group-combined {
  display: flex;

  input {
    flex: 1;
    border: {
      right: none;
      radius: 5px 0 0 5px; }
    margin: 0; }

  button {
    border-radius: 0 5px 5px 0 !important; } }

.form-title {
  margin-bottom: 10px; }

.flex {
  display: flex;

  &--1 {
    flex: 1; }

  &.flex-jc--space-between {
    justify-content: space-between; }

  &-jc--space-around {
    justify-content: space-around; }

  &.flex-jc--center {
    justify-content: center; }

  &.flex-jc--flex-end {
    justify-content: flex-end; }

  &.flex-ai--flex-start {
    align-items: flex-start; }

  &.flex-ai--flex-end {
    align-items: flex-end; }

  &.flex-ai--center {
    align-items: center; }

  &.flex-fd--column {
    flex-direction: column; }

  &.m-flex-fd--column {
    @include xs {
      flex-direction: column; } }

  &.flex-fd--row {
    flex-direction: row; }

  &.flex-fw--wrap {
    flex-wrap: wrap; } }

.text-bold {
  font-weight: 500 !important; }

.font-bold {
  font-weight: bold !important; }

.width--1-7 {
  width: calc(calc(100% / 7) - #{$gutter}) !important; }

.width--1-6 {
  width: calc(calc(100% / 6) - #{$gutter}) !important; }

.width--1-5 {
  width: calc(calc(100% / 5) - #{$gutter}) !important; }

.width--1-4 {
  width: calc(calc(100% / 4) - #{$gutter}) !important; }

.width--1-3 {
  width: calc(calc(100% / 3) - #{$gutter}) !important; }

.width--1-2 {
  width: calc(50% - #{$gutter}) !important; }

.width--3-4 {
  width: calc(75% - #{$gutter}) !important; }

.width--1-4, .width--1-3, .width--1-2, .width--3-4 {
  @include xs {
    width: 100% !important; }

  @include sm {
    width: 100% !important; } }

.page-holder--container {
  @include xs {
    padding: 0 15px; }

  @include sm {
    padding: 0 15px; } }

.no-background {
 background: none; }

.btn--no-background {
  background: none; }

.btn--border {
  border: 1px solid #e4e4e4; }

#collicare-backdrop {
  @include position(fixed, (0, 0, 0, 0));
  z-index: -1;
  display: none;
  background: red;

  &.backdrop--is-open {
    display: block;
    z-index: 1; } }

.align-center {
  text-align: center !important; }

button {
  font-size: 14px;

  &.button--margin-bottom {
    margin-bottom: 15px; } }

input.input, select.input {
  @include size(100%, 33px);
  display: block;
  border: 1px solid $light-gray {
    radius: 4px; }
  background: none;
  margin-bottom: 8px;
  font-size: $font-xs;
  padding: 0 6px;

  &.input--no-margin-bottom {
      margin-bottom: 0; } }

.float-right {
  float: right; }

.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0 !important; }

.page-actions {
  a {
    padding-right: 10px;

    &:not(:first-of-type) {
      margin-left: 5px; } } }

.contact-container {
  @include flex(center, center);

  div.img-container {
    @include size(120px, 120px);
    border-radius: 50%;
    background: {
      size: cover !important;
      position: center !important; } }

  div.details {
    margin-left: 30px;

    [class^="details-"] {
      @include flex(center, flex-start);

      i {
        margin-right: 5px;
        font-size: 22px !important; } }

    [class$="name"] {
      font: {
        size: 24px;
        weight: 600; }
      margin-bottom: 10px; } } }

.margin-top--20 {
  margin-top: 20px; }

.margin-top--10 {
  margin-top: 10px; }

.margin-top--5 {
  margin-top: 5px; }

.margin-right--10 {
  margin-right: 10px; }

.margin-right--20 {
  margin-right: 20px; }

.padding-7 {
  padding: 7px; }

.default-avatar {
  color: #9a9a9a !important;
  font-size: 40px !important;

  &--big {
    font-size: 200px !important; }

  &--contacts {
    font-size: 140px !important; } }

.image-profile {
  background: {
    size: cover;
    position: center; } }

.form {
  &--border-bottom {
    border-bottom: 1px solid lighten($light-gray, 40%);
    margin-bottom: 15px; }

  &--padding-bottom {
    padding-bottom: 15px; } }

.customer-support-container {
  @include xs {
    flex-direction: column;

    .accordion-content {
      max-height: 3000px; } } }

.page-header {
  @include xs {
    flex-direction: column;
    margin-bottom: 10px; } }

.list-item-form {
  background: #eee;
  padding: 15px;
  margin-top: 5px;
  border-radius: 5px;

  button {
    margin-top: 5px; }

  input, textarea {
    background: white;
    border: 1px solid #e4e4e4; } }

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; }

.wave-graph-content {
  padding: {
    left: 15px !important;
    right: 15px !important; } }

.wave-diagram-filter-dropdown-item {
  button {
    background: none !important;
    border-width: 0 1px 0 0 !important;
    padding: 10px 10px;

    span {
      font-size: 14px !important; } } }

.wave-chart-container {
  padding: 10px 0 0;

  .wave-chart-header {
    padding-right: 5px;

    .wave-chart-header-dot {
      height: 10px;
      width: 10px;
      background: #f59637 !important;
      border-radius: 10px;
      margin-right: 5px;

      &-prev {
        background: #8d8c86 !important; } } }

  .wave-chart-body {
    margin-left: -15px; } }

.calendar {
  &-header {
    font-size: 20px; } }

.calendar-modal {
  position: absolute;
  right: 20px;
  z-index: 9999;
  width: 1040px;
  background: rgb(247, 247, 247);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;

  &--left {
    right: auto;
    left: 20px; }

  input.input {
    background: white;
    border: 1px solid #f2f2f2;
    margin-bottom: 0; } }

.filter-button {
  button {
    border-radius: 5px;
    padding: 10px 45px;
    font-weight: bold;
    width: calc(50% - 12px); }

  .filter-button-item {
    background-color: #f59637;
    color: #FFFFFF; }

  .filter-button-cancel {
    background: white;
    color: black; } }

.wave-diagram-select {
  p {
    margin-right: 20px; }

  .select {
    button {
      background: white; }

    .icon {
      border-left: 1px solid #ededed; } } }

.wave-diagram-switch {
  margin: 10px 0;

  p {
    margin-left: 10px; } }

.compared-to {
  font-size: 13px;
  color: #bfbfbf; }

.pie-chart {
  padding: 0 15px; }

.d-pie-chart + .d-pie-chart {
  z-index: 9; }

.draggable-content.draggable-content--no-padding.pie-chart-1 {
  padding-bottom: 20px !important; }

.custom-container {
  padding: 30px;
  position: relative;

  .page-header {
    width: calc(100vw - 64px) !important;
    position: sticky;
    left: 30px; }

  .page-content {
    .filter-action-buttons {
      position: sticky;
      width: calc(100vw - 64px) !important;
      left: 30px; }


    .table-filter {
      margin-top: 2px; }

    .custom-paginator {
      display: flex;
      justify-content: flex-end; }

    .pagination {
      position: sticky;
      right: 30px; } } }

.overflow-x-scroll {
  overflow-x: scroll; }

.rounded-icons {
    position: relative;
    @include transition(0.3s border $ease-in-out-quad);

    i {
      color: lighten($black, 25%) !important;
      background: #F1F1F1;
      padding: 4px;
      border-radius: 50%; }

    i:hover {
      color: $color-danger !important;
      background: #F5F5F5; } }
