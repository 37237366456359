@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
.full-size {
  display: flex;
  align-items: center;
  justify-content: center; }

.full-size {
  display: flex;
  align-items: center;
  justify-content: center; }

.notification-anim-enter {
  opacity: 0.01;
  -webkit-transform: translateX(100px);
          transform: translateX(100px); }

.notification-anim-enter.notification-anim-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.notification-anim-exit {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.notification-anim-exit.notification-anim-exit-active {
  opacity: 0.01;
  -webkit-transform: translateX(100px);
          transform: translateX(100px);
  -webkit-transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.notification-anim-to-right-enter {
  opacity: 0.01;
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px); }

.notification-anim-to-right-enter.notification-anim-to-right-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.notification-anim-to-right-exit {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.notification-anim-to-right-exit.notification-anim-to-right-exit-active {
  opacity: 0.01;
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
  -webkit-transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.fade-scale-enter {
  opacity: 0;
  -webkit-transform: scale(1.8);
          transform: scale(1.8); }

.fade-scale-enter-done {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.fade-scale-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.fade-scale-exit-active {
  opacity: 0;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.notification-item-container-enter {
  opacity: 0; }

.notification-item-container-enter-active {
  opacity: 1;
  -webkit-transition: all 150ms ease-in;
  transition: all 150ms ease-in; }

.notification-item-container-exit {
  opacity: 1; }

.notification-item-container-exit-active {
  opacity: 0;
  -webkit-transition: all 150ms ease-in;
  transition: all 150ms ease-in; }

.page-transition-appear .page-header {
  opacity: 0.01;
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px); }
  .page-transition-appear .page-header .page-actions a {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0.01; }

.page-transition-appear .page-content {
  opacity: 0.01;
  -webkit-transform: translateY(80px);
          transform: translateY(80px); }
  .page-transition-appear .page-content .filter-container, .page-transition-appear .page-content .table-animate, .page-transition-appear .page-content .tab-body, .page-transition-appear .page-content .dashboard-container .card {
    opacity: 0.01;
    -webkit-transform: translateY(100px);
            transform: translateY(100px); }

.page-transition-appear-done .page-content, .page-transition-appear-done .page-header {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.page-transition-appear-done .page-content .filter-container, .page-transition-appear-done .page-content .table-animate, .page-transition-appear-done .page-content .tab-body, .page-transition-appear-done .page-content .dashboard-container .card {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: 0.3s opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s box-shadow 0.2s linear, 0.3s -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.3s opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s box-shadow 0.2s linear, 0.3s -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.3s transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s box-shadow 0.2s linear;
  transition: 0.3s transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), 0.3s box-shadow 0.2s linear, 0.3s -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1); }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(6) {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(7) {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(8) {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(9) {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(10) {
  -webkit-transition-delay: 1s;
          transition-delay: 1s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(11) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(12) {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(13) {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(14) {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(15) {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(16) {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(17) {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(18) {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s; }

.page-transition-appear-done .page-content .dashboard-container .card:nth-child(19) {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s; }

.page-transition-appear-done .page-header {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s; }
  .page-transition-appear-done .page-header .page-actions a {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.3s all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: 0.3s all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .page-transition-appear-done .page-header .page-actions a:nth-child(1) {
    -webkit-transition-delay: 0.25s;
            transition-delay: 0.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(2) {
    -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(3) {
    -webkit-transition-delay: 0.75s;
            transition-delay: 0.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(4) {
    -webkit-transition-delay: 1s;
            transition-delay: 1s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(5) {
    -webkit-transition-delay: 1.25s;
            transition-delay: 1.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(6) {
    -webkit-transition-delay: 1.5s;
            transition-delay: 1.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(7) {
    -webkit-transition-delay: 1.75s;
            transition-delay: 1.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(8) {
    -webkit-transition-delay: 2s;
            transition-delay: 2s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(9) {
    -webkit-transition-delay: 2.25s;
            transition-delay: 2.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(10) {
    -webkit-transition-delay: 2.5s;
            transition-delay: 2.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(11) {
    -webkit-transition-delay: 2.75s;
            transition-delay: 2.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(12) {
    -webkit-transition-delay: 3s;
            transition-delay: 3s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(13) {
    -webkit-transition-delay: 3.25s;
            transition-delay: 3.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(14) {
    -webkit-transition-delay: 3.5s;
            transition-delay: 3.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(15) {
    -webkit-transition-delay: 3.75s;
            transition-delay: 3.75s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(16) {
    -webkit-transition-delay: 4s;
            transition-delay: 4s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(17) {
    -webkit-transition-delay: 4.25s;
            transition-delay: 4.25s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(18) {
    -webkit-transition-delay: 4.5s;
            transition-delay: 4.5s; }
  .page-transition-appear-done .page-header .page-actions a:nth-child(19) {
    -webkit-transition-delay: 4.75s;
            transition-delay: 4.75s; }

.full-size {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999; }

.lds-ripple {
  display: inline-block;
  position: relative; }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #f59637;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
    .lds-ripple div:nth-child(2) {
      -webkit-animation-delay: -0.5s;
              animation-delay: -0.5s; }

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0; } }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/fonts/MaterialIcons-Regular.woff2) format("woff2"), url(/fonts/MaterialIcons-Regular.woff) format("woff"), url(/fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.blinking {
  -webkit-animation: blinkingText 0.8s infinite;
          animation: blinkingText 0.8s infinite; }

@-webkit-keyframes animate-item {
  0% {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px); }
  100% {
    -webkit-transform: translateX(1400px);
            transform: translateX(1400px); } }

@keyframes animate-item {
  0% {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px); }
  100% {
    -webkit-transform: translateX(1400px);
            transform: translateX(1400px); } }

@-webkit-keyframes blinkingText {
  0% {
    color: #000; }
  50% {
    color: transparent; }
  100% {
    color: #000; } }

@keyframes blinkingText {
  0% {
    color: #000; }
  50% {
    color: transparent; }
  100% {
    color: #000; } }

.item-preloader {
  position: relative;
  height: 30px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden; }
  .item-preloader:before {
    content: '';
    position: absolute;
    width: 250px;
    height: 100%;
    -webkit-animation: animate-item 2s linear infinite;
            animation: animate-item 2s linear infinite; }
  .item-preloader.item-preloader--light {
    background: rgba(255, 255, 255, 0.2); }
    .item-preloader.item-preloader--light:before {
      background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.01)), color-stop(rgba(255, 255, 255, 0.25)), to(rgba(255, 255, 255, 0.01)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.01));
      background: linear-gradient(to right, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.01)); }
  .item-preloader.item-preloader--dark {
    background: rgba(0, 0, 0, 0.1); }
    .item-preloader.item-preloader--dark:before {
      background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.01)), color-stop(rgba(0, 0, 0, 0.1)), color-stop(rgba(0, 0, 0, 0.15)), color-stop(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.01)));
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.01));
      background: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.01)); }

::-webkit-scrollbar {
  width: 9px;
  height: 9px; }

::-webkit-scrollbar-track {
  background: black; }

::-webkit-scrollbar-thumb {
  background: #f59637; }

::-webkit-scrollbar-thumb:hover {
  background: #b30000; }

[draggable] {
  cursor: pointer; }

.no-margin {
  margin: 0 !important; }

.ff-2 {
  font-family: "Lato", sans-serif; }

.fs-xs {
  font-size: 12px; }

.fs-sm {
  font-size: 14px; }

.fs-md {
  font-size: 14px; }

.fs-lg {
  font-size: 16px; }

.text-center {
  text-align: center !important; }

.top-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2099; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

*, *:after, *:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

a, button {
  cursor: pointer;
  border: none;
  text-decoration: none; }

body {
  font-family: "Lato", sans-serif;
  font-size: 14px; }

textarea, input, select {
  font-family: "Lato", sans-serif;
  font-size: 12px; }

.page-holder {
  position: relative;
  z-index: 2;
  margin-top: 200px; }
  .page-holder--container {
    min-height: calc(100vh - 200px);
    background: #efefef; }

.container {
  display: flex;
  max-width: 1390px;
  width: 100%;
  flex-direction: column;
  margin: 0 auto; }
  .container.container-horizontal {
    flex-direction: row; }
  .container.content-container {
    padding-top: 40px;
    padding-bottom: 40px; }
    @media only screen and (max-width: 576px) {
      .container.content-container {
        padding-top: 20px; } }
  .container.container--no-pading {
    padding: 0; }

.container--full-width, .full-width {
  width: 100%; }

.width--initial {
  width: auto !important;
  width: initial !important; }

.title {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.page-title {
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px; }
  .page-title span {
    margin-left: 20px;
    color: #bfbfbf; }

.page-content {
  margin-top: 20px; }
  @media only screen and (max-width: 576px) {
    .page-content {
      flex-direction: column; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  min-width: 150px;
  padding-left: 10px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  overflow: hidden; }
  .btn[disabled] {
    opacity: 0.4; }
  .btn.btn-transparent {
    height: auto;
    height: initial;
    min-width: 0;
    min-width: initial;
    padding: 0;
    background: none; }
  .btn.btn-icon-only {
    min-width: auto;
    text-align: center;
    padding: 0 10px; }
    .btn.btn-icon-only:not(:first-of-type) {
      margin-left: 5px; }
    .btn.btn-icon-only i {
      font-size: 20px; }
  .btn i, .btn img {
    -webkit-transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .btn img.loader {
    height: 70%;
    position: absolute;
    right: -100px;
    opacity: 0; }
  .btn.btn-primary {
    background: #54534A;
    color: white !important;
    -webkit-transition: all 0.15s linear;
    transition: all 0.15s linear; }
    .btn.btn-primary:hover:not(:disabled) {
      background: #f59637; }
  .btn.btn-danger {
    background: #ff5454;
    color: white; }
  .btn.no-animation {
    justify-content: space-between;
    padding-right: 10px; }
  .btn:not(.no-animation).btn-with-icon {
    justify-content: space-between;
    -webkit-transition: 0.5s padding-right cubic-bezier(0.165, 0.84, 0.44, 1), 0.8s padding-left cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s padding-right cubic-bezier(0.165, 0.84, 0.44, 1), 0.8s padding-left cubic-bezier(0.165, 0.84, 0.44, 1); }
    .btn:not(.no-animation).btn-with-icon i {
      font-size: 20px;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
      line-height: 1; }
    .btn:not(.no-animation).btn-with-icon:hover {
      padding-left: 25px;
      padding-right: 10px; }
  .btn.btn-loading img {
    right: 10px;
    opacity: 1; }
  .btn.btn-loading.btn-with-icon i {
    -webkit-transform: translateX(100px);
            transform: translateX(100px); }
  .btn.btn--no-radius {
    border-radius: 0; }
  .btn.btn-text-transform--none {
    text-transform: none; }
  .btn.btn-visibility {
    background: white;
    min-width: 0;
    min-width: initial;
    text-transform: none;
    margin-left: 5px;
    padding: 0 5px 0 0;
    -webkit-transition: 0.15s all linear;
    transition: 0.15s all linear; }
    .btn.btn-visibility:hover, .btn.btn-visibility:active, .btn.btn-visibility:focus {
      background: #ffd8b0;
      padding: 5px 10px 5px 5px; }
      .btn.btn-visibility:hover i.cc-close, .btn.btn-visibility:active i.cc-close, .btn.btn-visibility:focus i.cc-close {
        color: black !important; }
    .btn.btn-visibility button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;
      padding: 5px 0 5px 5px; }
    .btn.btn-visibility.btn-visibility--active {
      background: #f59637; }
      .btn.btn-visibility.btn-visibility--active button span, .btn.btn-visibility.btn-visibility--active button i {
        color: white !important; }
      .btn.btn-visibility.btn-visibility--active i.cc-close {
        color: rgba(255, 255, 255, 0.4) !important; }
        .btn.btn-visibility.btn-visibility--active i.cc-close:hover {
          color: rgba(255, 255, 255, 0.9) !important; }
    .btn.btn-visibility span {
      margin-right: 10px; }
    .btn.btn-visibility i {
      font-size: 20px;
      margin-right: 5px; }
      .btn.btn-visibility i.cc-close {
        margin-right: 0;
        cursor: pointer;
        -webkit-transition: 0.1s -webkit-transform linear;
        transition: 0.1s -webkit-transform linear;
        transition: 0.1s transform linear;
        transition: 0.1s transform linear, 0.1s -webkit-transform linear; }
        .btn.btn-visibility i.cc-close:hover {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
          color: #f59637 !important; }

.input-group input,
.input-group select,
.input-group textarea,
.input-group-combined input {
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px; }

.input-group {
  position: relative; }
  .input-group i.right {
    position: absolute;
    top: 5px;
    right: 5px; }
  .input-group.input-group--big-margin {
    margin-bottom: 10px; }
  .input-group label {
    font-size: 12px;
    margin-bottom: 5px;
    display: block; }
  .input-group input, .input-group select, .input-group textarea {
    width: 100%;
    height: 33px;
    margin-bottom: 8px;
    background: white;
    border: 1px solid #808080; }
  .input-group textarea {
    height: 100px;
    padding: 10px; }

.input-group-combined {
  display: flex; }
  .input-group-combined input {
    flex: 1 1;
    border-right: none;
    border-radius: 5px 0 0 5px;
    margin: 0; }
  .input-group-combined button {
    border-radius: 0 5px 5px 0 !important; }

.form-title {
  margin-bottom: 10px; }

.flex {
  display: flex; }
  .flex--1 {
    flex: 1 1; }
  .flex.flex-jc--space-between {
    justify-content: space-between; }
  .flex-jc--space-around {
    justify-content: space-around; }
  .flex.flex-jc--center {
    justify-content: center; }
  .flex.flex-jc--flex-end {
    justify-content: flex-end; }
  .flex.flex-ai--flex-start {
    align-items: flex-start; }
  .flex.flex-ai--flex-end {
    align-items: flex-end; }
  .flex.flex-ai--center {
    align-items: center; }
  .flex.flex-fd--column {
    flex-direction: column; }
  @media only screen and (max-width: 576px) {
    .flex.m-flex-fd--column {
      flex-direction: column; } }
  .flex.flex-fd--row {
    flex-direction: row; }
  .flex.flex-fw--wrap {
    flex-wrap: wrap; }

.text-bold {
  font-weight: 500 !important; }

.font-bold {
  font-weight: bold !important; }

.width--1-7 {
  width: calc(calc(100% / 7) - 10px) !important; }

.width--1-6 {
  width: calc(calc(100% / 6) - 10px) !important; }

.width--1-5 {
  width: calc(calc(100% / 5) - 10px) !important; }

.width--1-4 {
  width: calc(calc(100% / 4) - 10px) !important; }

.width--1-3 {
  width: calc(calc(100% / 3) - 10px) !important; }

.width--1-2 {
  width: calc(50% - 10px) !important; }

.width--3-4 {
  width: calc(75% - 10px) !important; }

@media only screen and (max-width: 576px) {
  .width--1-4, .width--1-3, .width--1-2, .width--3-4 {
    width: 100% !important; } }

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .width--1-4, .width--1-3, .width--1-2, .width--3-4 {
    width: 100% !important; } }

@media only screen and (max-width: 576px) {
  .page-holder--container {
    padding: 0 15px; } }

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .page-holder--container {
    padding: 0 15px; } }

.no-background {
  background: none; }

.btn--no-background {
  background: none; }

.btn--border {
  border: 1px solid #e4e4e4; }

#collicare-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: none;
  background: red; }
  #collicare-backdrop.backdrop--is-open {
    display: block;
    z-index: 1; }

.align-center {
  text-align: center !important; }

button {
  font-size: 14px; }
  button.button--margin-bottom {
    margin-bottom: 15px; }

input.input, select.input {
  width: 100%;
  height: 33px;
  display: block;
  border: 1px solid #808080;
    border-radius: 4px;
  background: none;
  margin-bottom: 8px;
  font-size: 12px;
  padding: 0 6px; }
  input.input.input--no-margin-bottom, select.input.input--no-margin-bottom {
    margin-bottom: 0; }

.float-right {
  float: right; }

.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0 !important; }

.page-actions a {
  padding-right: 10px; }
  .page-actions a:not(:first-of-type) {
    margin-left: 5px; }

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .contact-container div.img-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: cover !important;
    background-position: center !important; }
  .contact-container div.details {
    margin-left: 30px; }
    .contact-container div.details [class^="details-"] {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .contact-container div.details [class^="details-"] i {
        margin-right: 5px;
        font-size: 22px !important; }
    .contact-container div.details [class$="name"] {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px; }

.margin-top--20 {
  margin-top: 20px; }

.margin-top--10 {
  margin-top: 10px; }

.margin-top--5 {
  margin-top: 5px; }

.margin-right--10 {
  margin-right: 10px; }

.margin-right--20 {
  margin-right: 20px; }

.padding-7 {
  padding: 7px; }

.default-avatar {
  color: #9a9a9a !important;
  font-size: 40px !important; }
  .default-avatar--big {
    font-size: 200px !important; }
  .default-avatar--contacts {
    font-size: 140px !important; }

.image-profile {
  background-size: cover;
  background-position: center; }

.form--border-bottom {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px; }

.form--padding-bottom {
  padding-bottom: 15px; }

@media only screen and (max-width: 576px) {
  .customer-support-container {
    flex-direction: column; }
    .customer-support-container .accordion-content {
      max-height: 3000px; } }

@media only screen and (max-width: 576px) {
  .page-header {
    flex-direction: column;
    margin-bottom: 10px; } }

.list-item-form {
  background: #eee;
  padding: 15px;
  margin-top: 5px;
  border-radius: 5px; }
  .list-item-form button {
    margin-top: 5px; }
  .list-item-form input, .list-item-form textarea {
    background: white;
    border: 1px solid #e4e4e4; }

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; }

.wave-graph-content {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.wave-diagram-filter-dropdown-item button {
  background: none !important;
  border-width: 0 1px 0 0 !important;
  padding: 10px 10px; }
  .wave-diagram-filter-dropdown-item button span {
    font-size: 14px !important; }

.wave-chart-container {
  padding: 10px 0 0; }
  .wave-chart-container .wave-chart-header {
    padding-right: 5px; }
    .wave-chart-container .wave-chart-header .wave-chart-header-dot {
      height: 10px;
      width: 10px;
      background: #f59637 !important;
      border-radius: 10px;
      margin-right: 5px; }
      .wave-chart-container .wave-chart-header .wave-chart-header-dot-prev {
        background: #8d8c86 !important; }
  .wave-chart-container .wave-chart-body {
    margin-left: -15px; }

.calendar-header {
  font-size: 20px; }

.calendar-modal {
  position: absolute;
  right: 20px;
  z-index: 9999;
  width: 1040px;
  background: #f7f7f7;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  padding: 25px; }
  .calendar-modal--left {
    right: auto;
    left: 20px; }
  .calendar-modal input.input {
    background: white;
    border: 1px solid #f2f2f2;
    margin-bottom: 0; }

.filter-button button {
  border-radius: 5px;
  padding: 10px 45px;
  font-weight: bold;
  width: calc(50% - 12px); }

.filter-button .filter-button-item {
  background-color: #f59637;
  color: #FFFFFF; }

.filter-button .filter-button-cancel {
  background: white;
  color: black; }

.wave-diagram-select p {
  margin-right: 20px; }

.wave-diagram-select .select button {
  background: white; }

.wave-diagram-select .select .icon {
  border-left: 1px solid #ededed; }

.wave-diagram-switch {
  margin: 10px 0; }
  .wave-diagram-switch p {
    margin-left: 10px; }

.compared-to {
  font-size: 13px;
  color: #bfbfbf; }

.pie-chart {
  padding: 0 15px; }

.d-pie-chart + .d-pie-chart {
  z-index: 9; }

.draggable-content.draggable-content--no-padding.pie-chart-1 {
  padding-bottom: 20px !important; }

.custom-container {
  padding: 30px;
  position: relative; }
  .custom-container .page-header {
    width: calc(100vw - 64px) !important;
    position: -webkit-sticky;
    position: sticky;
    left: 30px; }
  .custom-container .page-content .filter-action-buttons {
    position: -webkit-sticky;
    position: sticky;
    width: calc(100vw - 64px) !important;
    left: 30px; }
  .custom-container .page-content .table-filter {
    margin-top: 2px; }
  .custom-container .page-content .custom-paginator {
    display: flex;
    justify-content: flex-end; }
  .custom-container .page-content .pagination {
    position: -webkit-sticky;
    position: sticky;
    right: 30px; }

.overflow-x-scroll {
  overflow-x: scroll; }

.rounded-icons {
  position: relative;
  -webkit-transition: 0.3s border cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: 0.3s border cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .rounded-icons i {
    color: #404040 !important;
    background: #F1F1F1;
    padding: 4px;
    border-radius: 50%; }
  .rounded-icons i:hover {
    color: #ff5454 !important;
    background: #F5F5F5; }

.dropdown {
  position: relative;
  display: inline; }
  .dropdown button.btn-dropdown {
    display: flex;
    align-items: center; }
    .dropdown button.btn-dropdown.btn-dropdown--transparent {
      background: none;
      border: none; }
    .dropdown button.btn-dropdown.btn-primary {
      background: #f59637;
      color: white;
      padding: 4px 5px 5px 10px;
      border-radius: 5px; }
      .dropdown button.btn-dropdown.btn-primary i {
        color: white; }
    .dropdown button.btn-dropdown i {
      margin-left: 10px; }
  .dropdown .dropdown-options {
    background: white;
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 0 40px rgba(0, 0, 0, 0.15);
    min-width: 150px;
    overflow: hidden; }
    .dropdown .dropdown-options.dropdown-position-left {
      left: 0; }
      .dropdown .dropdown-options.dropdown-position-left:before {
        left: 5px; }
    .dropdown .dropdown-options.dropdown-position-right {
      left: auto;
      left: initial;
      right: 0; }
      .dropdown .dropdown-options.dropdown-position-right:before {
        right: 5px; }
    .dropdown .dropdown-options:before {
      position: absolute;
      top: -5px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white; }
    .dropdown .dropdown-options .dropdown--item,
    .dropdown .dropdown-options .dropdown--item * {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      font-weight: 500; }
    .dropdown .dropdown-options .dropdown--item {
      display: flex;
      align-items: center;
      height: 35px;
      padding: 0 10px;
      cursor: pointer;
      white-space: nowrap; }
      .dropdown .dropdown-options .dropdown--item:hover {
        background: #f3f3f3; }
      .dropdown .dropdown-options .dropdown--item.dropdown--item-primary {
        background: #f59637;
        color: white; }

.header-container {
  height: 70px;
  background: white;
  position: relative;
  z-index: 99999; }
  @media only screen and (max-width: 576px) {
    .header-container {
      padding: 0 15px; } }
  .header-container .container {
    flex-direction: row;
    justify-content: space-between;
    height: 100%; }
    .header-container .container div[class$='-container'] {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .header-container .container .left-container {
      flex: 1 1;
      justify-content: flex-start !important; }
      .header-container .container .left-container .input-group {
        width: 100%;
        height: 100%;
        flex: 1 1;
        margin-left: 20px;
        display: flex;
        align-items: center;
        border-width: 0 2px;
        border-style: solid;
        border-color: #f9f9f9;
        padding: 0 20px;
        position: relative; }
        @media only screen and (max-width: 576px) {
          .header-container .container .left-container .input-group {
            display: none; } }
        @media only screen and (min-width: 577px) and (max-width: 768px) {
          .header-container .container .left-container .input-group {
            display: none; } }
        .header-container .container .left-container .input-group.input-group--no-border {
          border: none; }
        .header-container .container .left-container .input-group.input-group--no-margin {
          margin-left: 0; }
        .header-container .container .left-container .input-group input {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          padding: 0 20px 0 55px;
          font-size: 14px;
          font-weight: 100; }
    .header-container .container .right-container {
      position: relative; }
      .header-container .container .right-container .notifications {
        position: absolute;
        right: -40px;
        top: 80px; }
        .header-container .container .right-container .notifications-item {
          min-width: 350px;
          background: white;
          z-index: 999999;
          border-radius: 5px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          overflow: hidden; }
          .header-container .container .right-container .notifications-item i.material-icons {
            font-size: 26px;
            margin: 18px 0 0 15px; }
          .header-container .container .right-container .notifications-item .actions {
            display: flex;
            flex-direction: column;
            min-width: 100px;
            min-height: 77px;
            justify-content: stretch;
            border-left: 1px solid #eee; }
            .header-container .container .right-container .notifications-item .actions button {
              flex: 1 1;
              background: none;
              border: none;
              font-size: 13px;
              font-weight: 300; }
              .header-container .container .right-container .notifications-item .actions button:hover {
                background: #eee; }
              .header-container .container .right-container .notifications-item .actions button.orange {
                color: #f59637; }
                .header-container .container .right-container .notifications-item .actions button.orange:hover {
                  background: #f59637;
                  color: white; }
              .header-container .container .right-container .notifications-item .actions button:first-of-type {
                border-bottom: 1px solid #eee; }
          .header-container .container .right-container .notifications-item .right {
            padding: 15px 0;
            flex: 1 1;
            margin-left: 10px; }
            .header-container .container .right-container .notifications-item .right .title {
              font-weight: 900; }
            .header-container .container .right-container .notifications-item .right .title, .header-container .container .right-container .notifications-item .right .content {
              font-size: 13px; }
            .header-container .container .right-container .notifications-item .right .content {
              color: #909090; }
          .header-container .container .right-container .notifications-item:not(:first-child) {
            margin-top: 15px; }
      .header-container .container .right-container .notification-container {
        position: relative;
        padding: 0 20px;
        border-left: 2px solid #f9f9f9;
        background: none;
        border: none; }
        .header-container .container .right-container .notification-container i {
          position: relative;
          z-index: 9; }
        .header-container .container .right-container .notification-container span {
          position: absolute;
          left: 33px;
          padding: 1px 0;
          background: #f59637;
          color: white;
          font-size: 12px;
          font-weight: 100;
          min-width: 22px;
          border-radius: 20px;
          text-align: center; }
      .header-container .container .right-container .details-user-content {
        margin-left: 20px; }
        .header-container .container .right-container .details-user-content p {
          text-align: right; }
          .header-container .container .right-container .details-user-content p.details-user--name {
            font-size: 14px;
            font-family: "Lato", sans-serif;
            font-weight: 600;
            margin-bottom: 3px; }
          .header-container .container .right-container .details-user-content p.details-user--role {
            color: #a3a3a3;
            font-size: 12px; }
      .header-container .container .right-container .header-avatar {
        position: absolute;
        right: -80px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center !important; }
  .header-container img.company-logo {
    height: 32px; }

.navigation-container .container ul.link-container li.link-lists a, .navigation-container .container ul.link-container li.link-lists-disabled a {
  display: flex;
  align-items: center;
  justify-content: center; }

.navigation-container {
  height: 130px;
  max-height: 130px;
  background: #54534A;
  -webkit-transition: max-height 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: max-height 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .navigation-container:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: #FFFFFF;
    height: 5px;
    bottom: 0px;
    z-index: 1; }
  .navigation-container--shrink {
    max-height: 100px; }
    @media only screen and (max-width: 576px) {
      .navigation-container--shrink {
        height: 130px;
        max-height: 130px; } }
    .navigation-container--shrink div.container ul.link-list {
      padding: 3px 20px; }
      @media only screen and (max-width: 576px) {
        .navigation-container--shrink div.container ul.link-list {
          padding: 15px 20px; } }
    .navigation-container--shrink div.container ul.link-container li.link-lists a .image-icon {
      -webkit-animation: scaleIn 0.15s forwards !important;
              animation: scaleIn 0.15s forwards !important; }
      @media only screen and (max-width: 576px) {
        .navigation-container--shrink div.container ul.link-container li.link-lists a .image-icon {
          -webkit-animation: none !important;
                  animation: none !important; } }
      @media only screen and (min-width: 577px) and (max-width: 768px) {
        .navigation-container--shrink div.container ul.link-container li.link-lists a .image-icon {
          -webkit-animation: none !important;
                  animation: none !important; } }
    .navigation-container--shrink div.container ul.link-container li.link-lists p {
      -webkit-animation: fadeOut 0.15s forwards !important;
              animation: fadeOut 0.15s forwards !important; }
      @media only screen and (max-width: 576px) {
        .navigation-container--shrink div.container ul.link-container li.link-lists p {
          -webkit-animation: none !important;
                  animation: none !important; } }
      @media only screen and (min-width: 577px) and (max-width: 768px) {
        .navigation-container--shrink div.container ul.link-container li.link-lists p {
          -webkit-animation: none !important;
                  animation: none !important; } }
  .navigation-container .container,
  .navigation-container ul.link-container,
  .navigation-container ul.link-container li,
  .navigation-container ul.link-container li a {
    height: 100%; }
  .navigation-container .container {
    flex-direction: initial;
    justify-content: space-between; }
    @media only screen and (max-width: 576px) {
      .navigation-container .container {
        flex-direction: column; } }
    .navigation-container .container .link-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 15px 20px;
      z-index: 2;
      border-bottom: 5px solid transparent;
      -webkit-transition: all 0.15s linear;
      transition: all 0.15s linear; }
      .navigation-container .container .link-list:hover {
        border-color: #f59637; }
      @media only screen and (max-width: 576px) {
        .navigation-container .container .link-list {
          flex-direction: row;
          justify-content: space-between; } }
      .navigation-container .container .link-list-button {
        width: 100%;
        padding: 5px;
        background-color: #75746D;
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 12px;
        cursor: pointer;
        margin: 2px 0; }
        @media only screen and (max-width: 576px) {
          .navigation-container .container .link-list-button {
            height: 100%;
            display: flex;
            align-items: center;
            width: calc(calc(100% / 3) - 10px); } }
        .navigation-container .container .link-list-button a {
          color: white; }
          .navigation-container .container .link-list-button a i {
            margin-left: 50px; }
        .navigation-container .container .link-list-button:hover {
          background-color: #f59637; }
        .navigation-container .container .link-list-button span {
          margin-left: 10px; }
    .navigation-container .container ul.link-container {
      display: flex;
      align-items: center;
      width: 80%; }
      @media only screen and (max-width: 576px) {
        .navigation-container .container ul.link-container {
          width: 100%; } }
      .navigation-container .container ul.link-container li.link-lists {
        min-width: 150px;
        z-index: 2;
        overflow: hidden; }
        @media only screen and (max-width: 576px) {
          .navigation-container .container ul.link-container li.link-lists {
            min-width: auto; } }
        @media only screen and (min-width: 577px) and (max-width: 768px) {
          .navigation-container .container ul.link-container li.link-lists {
            min-width: auto; } }
        .navigation-container .container ul.link-container li.link-lists:hover {
          background-color: #f59637; }
          .navigation-container .container ul.link-container li.link-lists:hover a {
            border-color: #f59637; }
            .navigation-container .container ul.link-container li.link-lists:hover a .image-icon {
              -webkit-transform: scale(1.3);
                      transform: scale(1.3); }
        .navigation-container .container ul.link-container li.link-lists a {
          padding: 0 30px;
          flex-direction: column;
          color: white;
          border-bottom: 5px solid transparent; }
          .navigation-container .container ul.link-container li.link-lists a.link-active {
            border-color: #f59637;
            background-color: #000000; }
          .navigation-container .container ul.link-container li.link-lists a p {
            font-weight: 300;
            animation: fadeOut 0.15s reverse forwards; }
            @media only screen and (min-width: 577px) and (max-width: 768px) {
              .navigation-container .container ul.link-container li.link-lists a p {
                display: none; } }
            @media only screen and (max-width: 576px) {
              .navigation-container .container ul.link-container li.link-lists a p {
                display: none; } }
          .navigation-container .container ul.link-container li.link-lists a .image-icon {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-transition: all 200s linear;
            transition: all 200s linear;
            animation: scaleIn 0.15s reverse forwards; }
            @media only screen and (min-width: 577px) and (max-width: 768px) {
              .navigation-container .container ul.link-container li.link-lists a .image-icon {
                -webkit-animation: none !important;
                        animation: none !important; } }
            @media only screen and (max-width: 576px) {
              .navigation-container .container ul.link-container li.link-lists a .image-icon {
                -webkit-animation: none !important;
                        animation: none !important; }
                .navigation-container .container ul.link-container li.link-lists a .image-icon i {
                  font-size: 30px !important; } }
      .navigation-container .container ul.link-container li.link-lists-disabled {
        min-width: 150px;
        z-index: 2;
        overflow: hidden; }
        @media only screen and (max-width: 576px) {
          .navigation-container .container ul.link-container li.link-lists-disabled {
            min-width: auto; } }
        @media only screen and (min-width: 577px) and (max-width: 768px) {
          .navigation-container .container ul.link-container li.link-lists-disabled {
            min-width: auto; } }
        .navigation-container .container ul.link-container li.link-lists-disabled:hover a .image-icon {
          -webkit-transform: scale(1.3);
                  transform: scale(1.3); }
        .navigation-container .container ul.link-container li.link-lists-disabled a {
          padding: 0 30px;
          flex-direction: column;
          color: #808080;
          border-bottom: 5px solid transparent; }
          .navigation-container .container ul.link-container li.link-lists-disabled a.link-active {
            border-color: #f59637;
            background-color: #000000; }
          .navigation-container .container ul.link-container li.link-lists-disabled a p {
            font-weight: 300;
            animation: fadeOut 0.15s reverse forwards; }
            @media only screen and (min-width: 577px) and (max-width: 768px) {
              .navigation-container .container ul.link-container li.link-lists-disabled a p {
                display: none; } }
            @media only screen and (max-width: 576px) {
              .navigation-container .container ul.link-container li.link-lists-disabled a p {
                display: none; } }
          .navigation-container .container ul.link-container li.link-lists-disabled a .image-icon {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-transition: all 200s linear;
            transition: all 200s linear;
            animation: scaleIn 0.15s reverse forwards; }
            @media only screen and (min-width: 577px) and (max-width: 768px) {
              .navigation-container .container ul.link-container li.link-lists-disabled a .image-icon {
                -webkit-animation: none !important;
                        animation: none !important; } }
            @media only screen and (max-width: 576px) {
              .navigation-container .container ul.link-container li.link-lists-disabled a .image-icon {
                -webkit-animation: none !important;
                        animation: none !important; }
                .navigation-container .container ul.link-container li.link-lists-disabled a .image-icon i {
                  font-size: 30px !important; } }

@-webkit-keyframes fadeOut {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0; } }

@keyframes fadeOut {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
    opacity: 0; } }

@-webkit-keyframes scaleIn {
  0% {
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1); }
  100% {
    -webkit-transform: translateY(10px) scale(1.3);
            transform: translateY(10px) scale(1.3); } }

@keyframes scaleIn {
  0% {
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1); }
  100% {
    -webkit-transform: translateY(10px) scale(1.3);
            transform: translateY(10px) scale(1.3); } }

@-webkit-keyframes rightMenu {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@keyframes rightMenu {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

