@import '../variables';
@import '../mixins';

$color-divider: #f9f9f9;

.header-container {
  height: 70px;
  background: white;
  position: relative;
  z-index: 99999;

  @include xs {
    padding: 0 15px; }

  .container {
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    div[class$='-container'] {
      height: 100%;
      @include flex(center, space-between); }

    .left-container {
      flex: 1;
      justify-content: flex-start !important;

      .input-group {
        @include size(100%, 100%);
        flex: 1;
        margin-left: 20px;
        display: flex;
        align-items: center;
        border: {
          width: 0 2px;
          style: solid;
          color: $color-divider; }
        padding: 0 20px;
        position: relative;

        @include xs {
          display: none; }

        @include sm {
          display: none; }

        &.input-group--no-border {
          border: none; }

        &.input-group--no-margin {
          margin-left: 0; }

        input {
          @include position(absolute, (0, 0, 0, 0));
          @include size(100%, 100%);
          background: none;
          border: none;
          padding: 0 20px 0 55px;
          font: {
            size: 14px;
            weight: 100; } } } }

    .right-container {
      position: relative;

      .notifications {
        position: absolute;
        right: -40px;
        top: 80px;

        &-item {
          min-width: 350px;
          background: white;
          z-index: 999999;
          border-radius: 5px;
          @include flex(flex-start, space-between);
          overflow: hidden;

          i.material-icons {
            font-size: 26px;
            margin: 18px 0 0 15px; }

          .actions {
            display: flex;
            flex-direction: column;
            min-width: 100px;
            min-height: 77px;
            justify-content: stretch;
            border-left: 1px solid #eee;

            button {
              flex: 1 1;
              background: none;
              border: none;
              font: {
                size: 13px;
                weight: 300; }

              &:hover {
                background: #eee; }

              &.orange {
                color: $color-primary;

                &:hover {
                  background: $color-primary;
                  color: white; } }

              &:first-of-type {
                border-bottom: 1px solid #eee; } } }

          .right {
            padding: 15px 0;
            flex: 1;
            margin-left: 10px;

            .title {
              font-weight: 900; }

            .title, .content {
              font-size: 13px; }

            .content {
              color: #909090; } }

          &:not(:first-child) {
            margin-top: 15px; } } }

      .notification-container {
        position: relative;
        padding: 0 20px;
        border-left: 2px solid $color-divider;
        background: none;
        border: none;

        i {
          position: relative;
          z-index: 9; }

        span {
          position: absolute;
          left: 33px;
          padding: 1px 0;
          background: $color-primary;
          color: white;
          font: {
            size: 12px;
            weight: 100; }
          min-width: 22px;
          border-radius: 20px;
          text-align: center; } }

      .details-user-content {
        margin-left: 20px;

        p {
          text-align: right;

          &.details-user--name {
            font: {
              size: 14px;
              family: $font-secondary;
              weight: 600; }
            margin-bottom: 3px; }

          &.details-user--role {
            color: #a3a3a3;
            font-size: 12px; } } }

      .header-avatar {
        position: absolute;
        right: -80px;
        background: {
          size: contain !important;
          repeat: no-repeat !important;
          position: center !important; } } } }


  img.company-logo {
    height: 32px; } }
