@import '../variables';
@import '../mixins';

.navigation-container {
  height: 130px;
  max-height: 130px;
  background: $nav-background;
  transition: max-height 0.2s $ease-in-out-quad;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: $white;
    height: 5px;
    bottom: 0px;
    z-index: 1; }

  &--shrink {
    max-height: 100px;

    @include xs {
      height: 130px;
      max-height: 130px; }

    div.container {
      ul.link-list {
        padding: 3px 20px;

        @include xs {
          padding: 15px 20px; } }

      ul.link-container li.link-lists {
        a {
          .image-icon {
            animation: scaleIn 0.15s forwards !important;

            @include xs {
              animation: none !important; }

            @include sm {
              animation: none !important; } } }

        p {
          animation: fadeOut 0.15s forwards !important;

          @include xs {
            animation: none !important; }

          @include sm {
            animation: none !important; } } } } }

  .container,
  ul.link-container,
  ul.link-container li,
  ul.link-container li a {
    height: 100%; }

  .container {
    flex-direction: initial;
    justify-content: space-between;

    @include xs {
      flex-direction: column; }

    .link-list {
      @include flex(center, center);
      flex-direction: column;
      height: 100%;
      padding: 15px 20px;
      z-index: 2;
      border-bottom: 5px solid transparent;
      transition: all 0.15s linear;

      &:hover {
        border-color:  $orange; }

      @include xs {
        flex-direction: row;
        justify-content: space-between; }

      &-button {
        width: 100%;
        padding: 5px;
        background-color: $light-gray-1;
        border-radius: 5px;
        color: $white;
        font-size: 12px;
        cursor: pointer;
        margin: 2px 0;

        @include xs {
          height: 100%;
          display: flex;
          align-items: center;
          width: calc(calc(100% / 3) - 10px); }

        a {
          color: white;

          i {
            margin-left: 50px; } }

        &:hover {
          background-color: $orange; }

        span {
          margin-left: 10px; } } }

    ul.link-container {
      display: flex;
      align-items: center;
      // justify-content: space-around
      width: 80%;

      @include xs {
        width: 100%; }

      li.link-lists {
        min-width: 150px;
        z-index: 2;
        overflow: hidden;

        @include xs {
          min-width: auto; }

        @include sm {
          min-width: auto; }

        &:hover {
          // background-color: darken($gray, 5%)
          background-color: $orange;

          a {
            border-color: $orange;

            .image-icon {
              transform: scale(1.3); } } }

        a {
          padding: 0 30px;
          @extend %centered-flex;
          flex-direction: column;
          color: white;
          border-bottom: 5px solid transparent;

          &.link-active {
            border-color: $orange;
            background-color: $black; }

          p {
            font-weight: 300;
            animation: fadeOut 0.15s reverse forwards;

            @include sm {
              display: none; }

            @include xs {
              display: none; } }

          .image-icon {
            height: 60px;
            @include flex(center, center);
            transition: all 200s linear;
            animation: scaleIn 0.15s reverse forwards;

            @include sm {
              animation: none !important; }

            @include xs {
              animation: none !important;

              i {
                font-size: 30px !important; } } } } }

      li.link-lists-disabled {
        min-width: 150px;
        z-index: 2;
        overflow: hidden;
        // background: $light-gray-1

        @include xs {
          min-width: auto; }

        @include sm {
          min-width: auto; }

        &:hover {

          a {
            // border-color: transparent

            .image-icon {
              transform: scale(1.3); } } }

        a {
          padding: 0 30px;
          @extend %centered-flex;
          flex-direction: column;
          color: $light-gray;
          border-bottom: 5px solid transparent;

          &.link-active {
            border-color: $orange;
            background-color: $black; }

          p {
            font-weight: 300;
            animation: fadeOut 0.15s reverse forwards;

            @include sm {
              display: none; }

            @include xs {
              display: none; } }

          .image-icon {
            height: 60px;
            @include flex(center, center);
            transition: all 200s linear;
            animation: scaleIn 0.15s reverse forwards;

            @include sm {
              animation: none !important; }

            @include xs {
              animation: none !important;

              i {
                font-size: 30px !important; } } } } } } } }


@keyframes fadeOut {
  from {
    transform: translateY(0);
    opacity: 1; }

  to {
    transform: translateY(-25px);
    opacity: 0; } }

@keyframes scaleIn {
  0% {
    transform: translateY(0) scale(1); }

  100% {
    transform: translateY(10px) scale(1.3); } }

@keyframes rightMenu {
  from {
    transform: scale(1); }

  to {
    transform: scale(0.8); } }
