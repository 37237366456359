// @import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700|Open+Sans:300,400,700')
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

// fonts
$font-primary: 'Lato', sans-serif;
$font-secondary: 'Lato', sans-serif;
$font-xs: 12px;
$font-sm: 14px;
$font-lg: 16px;

//colors
$light-gray: #808080;
$light-gray-1: #75746D;
$dark-gray: #404040;
$light-orange: #f89728;
$dark-orange: #ff7900;
$orange: #f59637;
$gray: #54534A;
$black: #000000;
$white: #FFFFFF;

// general
$gutter: 10px;

// base styles
$base-font-size: 14px;
$base-font-weight: 500;
$base-line-height: 20px;
$base-container-width: 1390px;

// title
$title-font-size: 16px;
$title-font-weight: 300;
$page-title-font-size: 36px;
$page-title-font-weight: 500;

// breakpoint
$breakpoint-lg: 993px;
$breakpoint-md: 769px;
$breakpoint-sm: 577px;
$breakpoint-xs: 1px;

// background colors
$color-bg-default: #efefef;
$color-primary: $orange;
$color-danger: #ff5454;
$color-input-border: #d6d6d6;
$color-select-border: #f2f2f2;
$color-table-row-border: #f0f0f0;
$color-table-header-label: #a1a1a1;

//color application
$nav-background: $gray;
$primary-button-color: $gray;

// easing - credits to bourbon
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
