@import '_variables';

@mixin xs {
  @media only screen and (max-width: $breakpoint-sm - 1) {
    @content; } }

@mixin sm {
  @media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1) {
    @content; } }

@mixin md {
  @media only screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
    @content; } }

@mixin lg {
  @media (min-width: $breakpoint-lg) {
    @content; } }

@mixin flex($alignItems, $justifyContent) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent; }

%centered-flex {
  display: flex;
  align-items: center;
  justify-content: center; }

@mixin flex-direction($direction: column) {
  flex-direction: $direction; }

@mixin position($position, $box-edge-values) {
  position: $position;
  top: nth($box-edge-values, 1);
  right: nth($box-edge-values, 2);
  bottom: nth($box-edge-values, 3);
  left: nth($box-edge-values, 4); }

@mixin size($width, $height) {
  width: $width;
  height: $height; }

@mixin transition($value) {
  -webkit-transition: $value;
  transition: $value; }

@mixin animation($value) {
  -webkit-animation: $value;
  animation: $value; }
